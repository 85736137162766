// color palette
$color-primary-dark: #150223;
$color-primary-light: #FFFFFF;
$color-accent-purple: #9C1A87;
$color-accent-green: #009645;
$color-accent-yellow: #F2BA4C;
$color-accent-blue: #008CCC;
$color-accent-orange: #E87D0D;
$color-accent-red: #D12421;
$color-hover-purple: #7A136A;
$color-hover-green: #007D2C;
$color-hover-yellow: #CD9933;
$color-hover-blue: #0070A3;
$color-hover-orange: #D87105;
$color-hover-red: #9A1A18;
$color-secondary-yellowish: #EFEBE2;
$color-secondary-darkGrey: #737B7D;
$color-secondary-mediumGrey: #AFAFAF;
$color-secondary-lightGrey: #D0D0D0;
$color-secondary-lightPurple: #B57FCA;
$color-form-blue: #7BB9FA;
$color-form-red: #F34E4E;
$color-modal-darkened: rgba(21, 2, 35, 0.3);