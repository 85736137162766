@import "../../styles/shared.scss";

.content {
  padding: 30px 21px;
  background: #fff;
  height: 100%;
}
.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 56px;
}
.static {
  flex: none;
}
.flex {
  flex: 1 1 auto;
  overflow: auto;
}
.flex-parent {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.actions {
  text-align: right;
  margin-top: 30px;
}
.action {
  margin-left: 30px;
}
.searchBox {
  border: 1px solid $color-secondary-mediumGrey;
  border-radius: 3px;
}
.searchInput.searchInput {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.searchList {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.searchItem {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.errorMessage {
  color: $color-accent-red;
}
.block {
  padding: 12px 18px;
}
.searchItemActive {
  background: rgba($color-secondary-lightGrey, 0.3);
  cursor: default;
}