@import "../../styles/shared.scss";

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 54px;

  @media screen and (max-width: 1150px) {
    padding-bottom: 33px;
  }
}

.containerList {
  display: flex;
  align-items: center;
  max-width: 1110px;

  @media screen and (max-width: 1149px) {
    max-width: 820px;
  }
  @media screen and (max-width: 900px) {
    max-width: 86vw;
  }
  @media screen and (max-width: 750px) {
    max-width: 88vw;
  }
  @media screen and (max-width: 600px) {
    max-width: 90vw;
  }
}

.titleContainer {
  width: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: 1150px) {
    margin-bottom: 19px;
  }
}

.title {
  font-size: 36px;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: $color-primary-dark;

  @media screen and (max-width: 1150px) {
    font-size: 24px;
  }

  @media screen and (max-width: 646px) {
    font-size: 18px;
    line-height: 23px;
  }
}

.newsSlider :global(.slick-slider) {

  :global(.slick-list) {

    @media screen and (max-width: 1150px) {
      width: 830px;
    }
    @media screen and (min-width: 886px) and (max-width: 900px) {
      width: 830px;
    }
    @media screen and (max-width: 885px) {
      width: 110.35%;
    }
    @media screen and (max-width: 750px) {
      width: 109.35%;
    }
    @media screen and (max-width: 600px) {
      width: 108.6%;
    }
    @media screen and (max-width: 500px) {
      width: 109.35%;
    }
    @media screen and (max-width: 424px) {
      width: 109.9%;
    }
    @media screen and (max-width: 374px) {
      width: 110.5%;
    }
    @media screen and (max-width: 320px) {
      width: 110.9%;
    }
    

    margin: 0 -15px;

    :global(.slick-track) {
      :global(.slick-slide)>div {
        padding: 0 15px;
      }
    }
  }
}