@import "../../styles/shared.scss";

.card {
  width: 350px;
  height: 344px;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;

  @media screen and (max-width: 1150px) {
    width: 250px;
  }
}

.info {
  height: 256px;
  top: 308px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 3px 3px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: $color-primary-light;
  padding: 10px 7px 7px 6px;
  transition: 0.5s;
}

@media screen and (min-width: 1150px) {
  .info:hover {
    overflow-y: auto;
    transform: translateY(-208px);
    -webkit-line-clamp: 100;
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .info::-webkit-scrollbar {
    display: none;
  }
}

.infoMore {
  height: 256px;
  top: 308px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 3px 3px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: $color-primary-light;
  padding: 30px 7px 15px 6px;
  transition: 0.5s;
  transform: translateY(-208px);
  -webkit-line-clamp: 100;
  padding-top: 30px;
  overflow: scroll;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

.image {
  border-radius: 3px;
  object-fit: cover;
}

.deleteIcon {
  border: none;
  position: absolute;
  cursor: pointer;
  top: 6px;
  right: 6px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg {
    width: 15px;
    height: 15px;
  }

  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: $color-primary-light;
}
