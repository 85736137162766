@import "../../styles/shared.scss";

.topCourts {
  margin: 30px 0 30px;
  @media screen and (min-width: 1440px) {
    margin-top: 60px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 15px;

  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: $color-primary-dark;

  @media screen and (min-width: 647px) {
    font-size: 24px;
  }

  @media screen and (min-width: 1150px) {
    margin-bottom: 19px;
    text-align: left;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.2px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1110px;
  margin: 0;
  padding: 0;
  list-style: none;

  @media screen and (max-width: 1150px) {
    max-width: 820px;
  }
  @media screen and (max-width: 1000px) {
    max-width: 82vw;
  }
  @media screen and (max-width: 900px) {
    max-width: 86vw;
  }
  @media screen and (max-width: 750px) {
    max-width: 88vw;
  }
  @media screen and (max-width: 600px) {
    max-width: 90vw;
  }
}

.listItem {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.topCourtsSlider :global(.slick-slider) {

  :global(.slick-list) {
    
    @media screen and (max-width: 1150px) {
      width: 830px;
    }
    @media screen and (min-width: 886px) and (max-width: 900px) {
      width: 830px;
    }
    @media screen and (max-width: 885px) {
      width: 109.9%;
    }
    @media screen and (max-width: 750px) {
      width: 108.8%;
    }
    @media screen and (max-width: 600px) {
      width: 108%;
    }
    @media screen and (max-width: 500px) {
      width: 108.57%;
    }
    @media screen and (max-width: 424px) {
      width: 108.98%;
    } 
    @media screen and (max-width: 374px) {
      width: 109.4%;
    }
    @media screen and (max-width: 320px) {
      width: 109.8%;
    }

    margin: 0 -12px;

    :global(.slick-track) {
      :global(.slick-slide)>div {
        padding: 0 12px;
      }
    }
  }
}

.adminCardWrapper {
  position: relative;
}
.adminDeleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: 0;
  background: none;
  z-index: 1;
  background: #fff;
  border-radius: 99999px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding: 5px;
}
.adminDeleteIcon {
  width: 100%;
  height: auto;
  path {
    fill: currentColor;
  }
}