.background {
  @media screen and (min-width: 768px) {
    background-image: url("../../public/svg/homePageBg.svg");
    background-position: calc(50% + 20px) -60px;
    background-size: 1100px 1400px;
    background-repeat: repeat-y;
  }

  @media screen and (min-width: 1150px) {
    background-size: auto;
  }
}
