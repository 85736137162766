%list {
  padding: 0;
  margin: 0;
  list-style: none;
}

%button {
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

%container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
