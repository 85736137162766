@import "../../styles/shared.scss";

.card {
  position: relative;
  cursor: pointer;
}

.topList {
  width: 257px;
  height: 291px;
}

.courtList {
  @media screen and (min-width: 320px) and (max-width: 374px) {
    width: 280px;
    height: 291px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    width: 338px;
    height: 334px;
  }
  @media screen and (min-width: 768px) {
    width: 257px;
    height: 291px;
  }

  .image {
    @media screen and (min-width: 375px) and (max-width: 767px) {
      height: 334px;
    }

    @media screen and (min-width: 768px) {
      height: 291px;
    }
  }
}

.inner {
  height: 100%;
}

.district {
  position: absolute;
  top: 15px;
  left: -12px;
  z-index: 2;
}

.image {
  border-radius: 3px;
}

.address {
  position: absolute;
  left: 0px;
  bottom: 20px;
  display: inline-block;
  padding: 6px;

  background-color: $color-primary-light;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  color: $color-primary-dark;
}

.outer {
  position: absolute;
  transform: translateY(-100%);
  opacity: 0;
  height: 155px;
  width: 100%;
  padding: 12px 12px 18px;

  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: $color-primary-light;
  transition: opacity 300ms;
  pointer-events: none;

  .card:hover & {
    opacity: 1;
    pointer-events: auto;
  }
}
